// Import files to create the webpack bundle
import '../css/styles.scss';
import '../css/wap_styles.css';
import '../css/insta_styles.css';

import './wapMain.js';

// Image carousel
let slideIndex = 0;
showSlides();

function showSlides() {
    const pagesWithoutCarousel = ['legal', 'privacidad', 'tocados', 'novias'];
    if (
        !pagesWithoutCarousel.some(page =>
            window.location.pathname.includes(page)
        )
    ) {
        const slides = document.getElementsByClassName('carousel');
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }
        slideIndex++;
        if (slideIndex > slides.length) {
            slideIndex = 1;
        }
        slides[slideIndex - 1].style.display = 'block';
        setTimeout(showSlides, 5000); // Change image every 3 seconds
    }
}

// Jump to an id on window loaded
window.onload = () => {
    const elementID = window.location.href.split('#')[1];
    if (elementID) {
        const element = document.querySelector(
            '#' + window.location.href.split('#')[1]
        );
        element.scrollIntoView({ block: "start", behavior: 'smooth' });
    }
};

// Check if the user has scrolled down to show the go up button
window.onscroll = () => {
    const goUpButton = document.querySelector('.go-up-button');
    if (window.pageYOffset > 1) {
        goUpButton.classList.remove('go-up-button-inactive');
        goUpButton.classList.add('go-up-button-active');
    } else {
        goUpButton.classList.add('go-up-button-inactive');
        setTimeout(() => {
            goUpButton.classList.remove('go-up-button-active');
        }, 700);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    // Replace copyright year
    const copyYear = document.querySelector('#copyright-year');
    const now = new Date();
    copyYear.textContent = now.getFullYear();

    // Mis novias cover
    if (window.location.href.includes('novias')) {
        const noviasImg = document.querySelector('#mis-novias-image');
        noviasImg.classList.add('mis-novias-image-active');
        const footer = document.querySelector('footer');

        // Appear text after 1 second
        const noviasTitle = document.querySelector('.mis-novias-title');
        setTimeout(() => {
            noviasTitle.style.display = 'block';
            noviasTitle.style.animationName = 'text_appear';
        }, 1000);

        // Appear text after 1.6 seconds
        const noviasText = document.querySelector('.mis-novias-text');
        setTimeout(() => {
            noviasText.style.display = 'block';
            noviasText.style.animationName = 'text_appear';
        }, 1600);

        // Appear text after 2.2 seconds
        const noviasBtn = document.querySelector('.mis-novias-button');
        setTimeout(() => {
            noviasBtn.style.display = 'block';
            noviasBtn.style.animationName = 'text_appear';
        }, 2200);

        noviasBtn.addEventListener('click', () => {
            footer.style.marginTop = "70vh";
            // Hide cover
            const noviasContainer = document.querySelector('#gallery-cover');
            noviasContainer.style.animationName = 'right-fade-out';
            setTimeout(() => {
                noviasContainer.style.display = 'none';
            }, 600);
            
            // Show gallery
            const noviasGallery = document.querySelector('#novias-gallery');
            setTimeout(() => {
                noviasGallery.style.display = 'block';
                noviasGallery.style.animationName = 'left-fade-in';
                footer.style.marginTop = "3rem";
            }, 700);
        });
    }

    // Contact form - Uploadcare plugin
    if (window.location.href.includes('cita')) {
        // Appointment selection
        const faceAppointmentBtn = document.querySelector('#enlace-presencial');
        const onlineAppointmentBtn = document.querySelector('#enlace-online');
        const faceAppointment = document.querySelector('#cita-presencial');
        const onlineAppointment = document.querySelector('#cita-online');
        const contactForm = document.querySelector('#contact-form');
        const appointmentInput = document.querySelector('#tipo-cita');

        faceAppointmentBtn.addEventListener('click', () => {
            // Hide the online appointment section if it is open
            if (onlineAppointment.style.display === 'block') {
                onlineAppointment.style.display = 'none';
                contactForm.style.display = 'none';
            }
            // Show the face-to-face appointment section
            if (faceAppointment.style.display === 'block') {
                faceAppointment.style.display = 'none';
                contactForm.style.display = 'none';
                faceAppointment.style.maxHeight = null;
            } else {
                appointmentInput.value = 'presencial';
                contactForm.style.display = 'block';
                faceAppointment.style.display = 'block';
                faceAppointment.style.maxHeight = faceAppointment.scrollHeight + 'px';
                faceAppointment.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
        });

        onlineAppointmentBtn.addEventListener('click', () => {
            // Hide the face-to-face appointment section if it is open
            if (faceAppointment.style.display == 'block') {
                faceAppointment.style.display = 'none';
                contactForm.style.display = 'none';
            }
            // Show the online appointment section
            if (onlineAppointment.style.display == 'block') {
                onlineAppointment.style.display = 'none';
                contactForm.style.display = 'none';
                onlineAppointment.style.maxHeight = null;
            } else {
                appointmentInput.value = 'online';
                contactForm.style.display = 'block';
                onlineAppointment.style.display = 'block';
                onlineAppointment.style.maxHeight = onlineAppointment.scrollHeight + 'px';
                onlineAppointment.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
        });

        const widget = uploadcare.Widget('[role=uploadcare-uploader]');

        // Replace button text
        widget.inputElement.nextSibling.querySelector(
            '.uploadcare--widget__button_type_open'
        ).innerHTML = widget.inputElement.getAttribute('data-btn-text');

        // Maximum size of 8 MB
        widget.validators.push(function (fileInfo) {
            if (fileInfo.size !== null && fileInfo.size > 1024 * 1024 * 8) {
                throw new Error('fileMaximumSize');
            }
        });

        // Show image preview inside the form
        const imageContainer = document.querySelector('#photos-container');

        // onChange does not listen to remove events, so I use it only for
        // added elements
        widget.onChange(function (group) {
            // Clean any previous preview
            while (imageContainer.firstChild) {
                imageContainer.removeChild(imageContainer.firstChild);
            }
            // Create the thumbnails
            createThumbnails(group.files(), imageContainer);
        });
        
        // onDialogOpen for the removed element event
        widget.onDialogOpen(dialog => {
            // listen to "file removed" event
            dialog.fileColl.onRemove.add(file => {
                while (imageContainer.firstChild) {
                    imageContainer.removeChild(imageContainer.firstChild);
                }
                createThumbnails(dialog.fileColl.__items, imageContainer);
            });
        });
    }
});

function createThumbnails(filesList, thumbnailGallery) {
    filesList.forEach(file => {
        file.done(fileInfo => {
            const preview = document.createElement('img');
            preview.src = fileInfo.cdnUrl + '-/resize/200x/';
            thumbnailGallery.appendChild(preview);
        });
    });
}

// Menu burger
const menu = document.querySelector('#menu-burguer');
const navLinks = document.querySelector('#nav-links');

menu.addEventListener('click', () => {
    if (navLinks.style.display === 'flex') {
        navLinks.style.animationName = 'menu_disappear';
        setTimeout(() => {
            navLinks.style.display = 'none';
        }, 100);
    } else {
        navLinks.style.animationName = 'menu_appear';
        navLinks.style.display = 'flex';
    }
});
